import { Language } from "@prisma/client";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../store/modules/user";
import { selectHasSession } from "../store/modules/user/selectors";
import { setCookie } from "../utils/cookies";

export function useLanguage() {
  const { i18n } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const hasSession = useSelector(selectHasSession);

  const onLanguageChange = useCallback(() => {
    const newLocale = i18n.language === "en" ? "fr" : "en";

    // Navigate to new language
    router.push(router, undefined, {
      locale: newLocale,
    });

    // Remember language
    setCookie("NEXT_LOCALE", newLocale, 365);

    if (hasSession) {
      dispatch(userActions.editLanguage(newLocale));
    }
  }, [i18n]);

  return { onLanguageChange, language: i18n.language as Language };
}
