import { TOptions } from "i18next";
import { useTranslation } from "next-i18next";
import Head from "next/head";
import React from "react";

export interface ISEOProps {
  title: string;
  titleParams?: TOptions;
}

export default function SEO({ title, titleParams }: ISEOProps) {
  const { t } = useTranslation("common");
  return (
    <Head>
      <title>{`Propty Meet - ${t(title, titleParams)}`}</title>
    </Head>
  );
}
